import { render } from "./SurveyViews.vue?vue&type=template&id=8eed2e2c"
import script from "./SurveyViews.vue?vue&type=script&lang=ts"
export * from "./SurveyViews.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "8eed2e2c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8eed2e2c', script)) {
    api.reload('8eed2e2c', script)
  }
  
  module.hot.accept("./SurveyViews.vue?vue&type=template&id=8eed2e2c", () => {
    api.rerender('8eed2e2c', render)
  })

}

script.__file = "src/views/SurveyViews.vue"

export default script