<template>
  <router-view></router-view>
</template>

<script lang="ts">
import { message } from 'ant-design-vue'
import { computed, defineComponent } from 'vue'
import { NavigationGuard, NavigationGuardWithThis, useRoute } from 'vue-router'

import { MESSAGE } from '@/constants/message'
import { PATH, ROUTE_NAME } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { fixEncodedQueryString } from '@/libs/utils'
import { useStore } from '@/store'
import { AuthActionEnum } from '@/store/enums/actions/auth'
import { NetworkActionEnum } from '@/store/enums/actions/network'
import { SurveyActionEnum } from '@/store/enums/actions/survey'
import { UserActionEnum } from '@/store/enums/actions/user'
import { WorkspaceActionEnum } from '@/store/enums/actions/workspace'
import { AuthStateEnum } from '@/store/enums/states/auth'
import { NetworkStateEnum } from '@/store/enums/states/network'
import { vuexActions } from '@/store/util'

const loadPage: NavigationGuardWithThis<undefined> | NavigationGuard = async (to, from, next) => {
  const store = useStore()
  const { workspaceId } = to.params
  const userId = store.state[ModuleNames.AUTH][AuthStateEnum.USER]?.id

  if (!workspaceId || typeof workspaceId !== 'string') {
    message.error({
      content: MESSAGE.UNEXPECTED_WORKSPACE_ID(workspaceId),
      duration: 3
    })
    setTimeout(() => {
      next(PATH.HOME)
    }, 3000)
    return
  } else {
    // If we will go to survey page
    if (to.name === ROUTE_NAME.SURVEY) {
      const { surveyId } = to.params
      const { surveyKey } = to.query

      if (surveyKey && typeof surveyKey === 'string') {
        const fixedSurveyKey = fixEncodedQueryString(surveyKey)
        const decodedSurveyKey = decodeURIComponent(fixedSurveyKey)

        await store.dispatch(
          vuexActions(ModuleNames.AUTH, AuthActionEnum.AUTH_SURVEY_KEY_LOGIN),
          decodedSurveyKey
        )
        next({
          name: ROUTE_NAME.QUESTIONNAIRE,
          params: {
            workspaceId,
            surveyId
          }
        })
        return
      }
    }

    await store.dispatch(vuexActions(ModuleNames.USER, UserActionEnum.GET_USERS))
    await store.dispatch(
      vuexActions(ModuleNames.WORKSPACE, WorkspaceActionEnum.GET_WORKSPACE),
      workspaceId
    )
    await store.dispatch(
      vuexActions(ModuleNames.NETWORK, NetworkActionEnum.GET_NETWORKS),
      workspaceId
    )
    const currentNetwork = store.state[ModuleNames.NETWORK][NetworkStateEnum.CURRENT_NETWORK]
    if (!currentNetwork) {
      message.error({
        content: MESSAGE.NETWORK_NOT_SET,
        duration: 3
      })
      next(false)
      return
    } else {
      // If we will go to survey page
      if (to.name === ROUTE_NAME.SURVEY || to.name === ROUTE_NAME.SURVEY_STOCHASTICS) {
        const { surveyId } = to.params
        const { surveyKey } = to.query

        if (surveyKey && typeof surveyKey === 'string') {
          const fixedSurveyKey = fixEncodedQueryString(surveyKey)
          const decodedSurveyKey = decodeURIComponent(fixedSurveyKey)

          await store.dispatch(
            vuexActions(ModuleNames.AUTH, AuthActionEnum.AUTH_SURVEY_KEY_LOGIN),
            decodedSurveyKey
          )
          next({
            name: ROUTE_NAME.QUESTIONNAIRE,
            params: {
              workspaceId,
              surveyId
            }
          })
        } else {
          if (Array.isArray(surveyId) || !surveyId) {
            message.error({
              content: MESSAGE.UNEXPECTED_SURVEY_ID(surveyId.toString()),
              duration: 3
            })
            setTimeout(() => {
              next(PATH.HOME)
            }, 3000)
            return
          } else if (!userId) {
            message.error({
              content: MESSAGE.UNEXPECTED_USER_ID(userId),
              duration: 3
            })
            setTimeout(() => {
              next(PATH.HOME)
            }, 3000)
            return
          } else {
            await store.dispatch(
              vuexActions(ModuleNames.SURVEY, SurveyActionEnum.GET_SURVEY),
              surveyId
            )
          }
        }
      }
      next()
    }
  }
}
export default defineComponent({
  beforeRouteEnter: loadPage as NavigationGuard,
  beforeRouteUpdate: loadPage as NavigationGuardWithThis<undefined>,
  setup() {
    const route = useRoute()
    const workspaceId = computed(() => {
      if (typeof route.params.workspaceId === 'string') {
        return route.params.workspaceId
      }
      return null
    })
    const surveyId = computed(() => {
      if (typeof route.params.surveyId === 'string') {
        return route.params.surveyId
      }
      return null
    })
    return {
      workspaceId,
      surveyId
    }
  }
})
</script>

<style scoped></style>
